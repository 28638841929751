@tailwind base;
@tailwind components;
@tailwind utilities;

/* General Scrollbar Styles */
::-webkit-scrollbar {
  width: 5px; /* Width for vertical scrollbars */
  height: 5px; /* Height for horizontal scrollbars */
  background-color: rgb(206, 212, 212); /* Background color of the scrollbar */
}

/* Scrollbar Thumb (The handle that moves) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

/* Scrollbar Thumb Hover Effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
